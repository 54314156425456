<template>
  <div class="OneApply">
    <div class="OneApply-content-con">
      <h2 class="OneApply-title">
        문의하기<img
          @click="applyCancel"
          src="~assets/image/ico/close-bk.svg"
          alt="닫기"
        />
      </h2>
      <div class="BasicInformation">
        <h2 class="BasicInformation-title title">기본 정보 입력</h2>
        <div class="BasicInformation-content">
          <div class="form-item-wrap">
            <div class="label">이름<i class="required"></i></div>
            <input type="text" class="inp" v-model="userNm" ref="userNm" />
          </div>
          <div class="form-item-wrap">
            <div class="label">전화번호<i class="required"></i></div>
            <input type="text" class="inp" v-model="phone" ref="phone" />
          </div>
          <div class="form-item-wrap">
            <div class="label">이메일<i class="required"></i></div>
            <input type="text" class="inp" v-model="email" ref="email" />
          </div>
        </div>
      </div>
      <div class="InquiryDetails">
        <h2 class="InquiryDetails-title title">
          문의 내용 입력<i class="required"></i>
        </h2>
        <div class="InquiryDetails-content">
          <div class="form-item-wrap">
            <div class="label">문의 서비스 유형<i class="required"></i></div>
            <select class="select" v-model="stSelect" ref="stSelect">
              <option value="NONE">선택</option>
              <option
                v-for="(typeOption, idx) in serviceTypeCodeList"
                :key="`tpOp_${idx}`"
                :value="typeOption.code"
                :selected="typeOption.code == stSelect"
              >
                {{ typeOption.codeValue }}
              </option>
            </select>
          </div>
          <div class="form-item-wrap">
            <div class="label">제목<i class="required"></i></div>
            <input
              type="text"
              class="inp Title"
              v-model="bordNm"
              ref="bordNm"
            />
          </div>
          <div class="form-item-wrap">
            <div class="label">내용<i class="required"></i></div>
            <textarea
              v-model="bordCont"
              ref="bordCont"
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
            <p class="textarea-info">({{ wordCount }} 자)</p>
          </div>
          <div class="form-item-wrap">
            <div class="label">파일 첨부</div>

            <!-- ㅅㅏ진업로드 UI 추가 -->
            <input
              type="text"
              class="inp addr1 file-value"
              :value="photoFileName"
              readonly
            />
            <input
              type="file"
              ref="fileInput"
              accept="image/*"
              class="hidden"
              @change="onFileChanged"
            />
            <button
              v-if="!isPhoto"
              type="button"
              id="btn-file-upload"
              class="btn-func"
              @click="onUploadClick"
            >
              사진업로드
            </button>
            <button
              v-if="isPhoto"
              type="button"
              id="btn-file-delete"
              class="btn-func"
              @click="deletePhoto"
            >
              사진삭제
            </button>
          </div>
          <div class="agree_wrap">
            <div class="agree_box">
              1.수집항목(필수): 이름,연락처,이메일주소
              <br />
              2.수집목적: 문의에 따른 원활한 상담 제공 <br />
              3.보유기간: 문의 처리 후 1년간 보관
            </div>
            <p class="check-wrap">
              <input
                type="checkbox"
                id="c1"
                v-model="privacyYn"
                true-value="Y"
                false-value="N"
              />
              <label for="c1">위의 ‘개인정보 수집 및 이용’에 동의합니다.</label>
            </p>
          </div>
          <div style="padding: 0" class="myp-btn-wrap">
            <button type="button" class="btn-save01" @click="apply">
              제출
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  uploadUserImg,
  putOneOnOneInfo,
  getBasicUserInfo,
  getServiceTypeCodeList,
} from 'Api/modules'
export default {
  props: {
    BoardPop: {
      type: Object,
    },
    ix: {
      type: Number,
      default: 0,
    },
    isOneOnOne: {
      type: Boolean,
      default: false,
    },
    propCd: {
      type: String,
      default: '4',
    },
  },
  data() {
    return {
      userNm: '',
      phone: '',
      stSelect: 'NONE',
      photoFileName: '',
      privacyYn: '',
      bordNm: '',
      bordCont: '',
      notcTrgt: '00002',
      notcScop: 'MEM',
      email: '',
      fileId: '',
      isDelete: false,
      isPhoto: false,
      isNoticeWidth: false,
      bordNum: null,
      serviceTypeCodeList: [],
      window: {
        width: 0,
        height: 0,
      },
      now: new Date(),
      isNoticePopup: true,
      wordCount: 0,
      isOneApply: this.isOneOnOne,
    }
  },
  watch: {
    window: {
      deep: true,
      handler() {
        if (this.window.width < 1023) {
          this.isNoticeWidth = false
        } else {
          this.isNoticeWidth = true
        }
      },
    },
    bordCont(newVal) {
      this.wordCount = this.byteLength(newVal)
    },
  },
  methods: {
    async fetchPersonalInfo() {
      const res = await getBasicUserInfo()
      this.email = res.email
      this.userNm = res.userNm
      this.phone = res.phone
    },
    async apply() {
      // console.log('photoFileName', saveData.fileId)
      let saveData = {}
      saveData = {
        notcScop: this.notcScop,
        propCd: this.propCd,
        cmpxCd: this.stSelect,
        // deptCd:
        notcTrgt: this.notcTrgt,
        bordCont: this.bordCont,
        regUser: this.account.userId,
        userNm: this.userNm,
        phone: this.phone,
        email: this.email,
        fileId: this.fileId,
        bordNm: this.bordNm,
      }
      console.log(saveData)
      if (!this.chkIsValidInput(saveData)) return false

      try {
        await putOneOnOneInfo(saveData)
        setTimeout(() => {
          this.$router.go()
        }, 500)

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    applyCancel() {
      this.isOneApply = false
      console.log('applyCancel', this.isOneApply)
      this.$emit('applyCancel', this.isOneApply)
    },
    validSpecial(data) {
      return /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g.test(
        data,
      )
    },
    validEmail(data) {
      return /[-\d\S.+_]+@[-\d\S.+_]+\.[\S]{2,4}/.test(data)
    },
    validUserNm(data) {
      return /^[가-힣]{2,5}$/.test(data)
    },
    validPhone(data) {
      return data.length >= 11 && /^[0-9]*$/.test(data)
    },
    chkIsValidInput(saveData) {
      if (!saveData.userNm) {
        this.$toasted.error('이름을 입력해주세요.')
        this.$refs['userNm'].focus()
        return false
      } else if (!this.validUserNm(saveData.userNm)) {
        this.$toasted.error('이름 형식이 올바르지 않습니다.')
        this.$refs['userNm'].focus()
        return false
      }
      if (!saveData.phone) {
        this.$toasted.error('핸드폰 번호를 입력해주세요.')
        this.$refs['phone'].focus()
        return false
      } else if (!this.validPhone(saveData.phone)) {
        this.$toasted.error('핸드폰 번호 형식이 올바르지 않습니다.')
        this.$refs['phone'].focus()
        return false
      }
      if (!saveData.email) {
        this.$toasted.error('이메일을 입력해주세요.')
        this.$refs['email'].focus()
        return false
      } else if (!this.validEmail(saveData.email)) {
        this.$toasted.error('이메일 형식이 올바르지 않습니다.')
        this.$refs['email'].focus()
        return false
      }

      if (this.stSelect == '') {
        this.$toasted.error('서비스 유형을 선택해주세요.')
        this.$refs['stSelect'].focus()
        return false
      }
      if (this.bordNm == '') {
        this.$toasted.error('제목을 입력해주세요.')
        this.$refs['bordNm'].focus()
        return false
      }
      if (this.bordCont == '') {
        this.$toasted.error('내용을 입력해주세요.')
        this.$refs['bordCont'].focus()
        return false
      } else if (this.validSpecial(saveData.bordCont)) {
        this.$toasted.error('내용에는 이모티콘을 사용하실 수 없습니다.')
        this.$refs['bordCont'].focus()
        return false
      }
      if (this.privacyYn == 'N' || this.privacyYn == '') {
        this.$toasted.error('개인 정보 보호 정책에 동의해주세요.')
        // this.$refs['checkOpen'].focus()
        return false
      }
      this.$toasted.show('문의가 접수되었습니다.')
      return true
    },
    byteLength(str) {
      let count = 0
      let ch = ''
      for (let i = 0; i < str.length; i++) {
        ch = str.charAt(i)
        if (escape(ch).length === 6) {
          count++
        }
        count++
      }
      return count
    },
    onUploadClick() {
      this.$refs['fileInput'].click()
    },
    deleteAttachedFile() {
      this.inputPhoto = null
      this.photo = null
      this.photoFileName = ''
      this.$refs['fileInput'].value = ''
    },
    deletePhoto() {
      this.isDelete = true
      this.isPhoto = false
      this.deleteAttachedFile()
      this.$toasted.error('첨부된 파일이 삭제되었습니다.')
    },
    async onFileChanged(event) {
      // 1M
      var maxFileSize = 1 * 1024 * 1000
      // console.log(file.size)
      const file = event.target.files[0]
      if (file) {
        // 파일 크기 제한

        //허용 파일 타입
        const isAcceptable = [
          'image/jpeg',
          'image/png',
          'image/bmp',
          'image/gif',
        ].includes(file.type)
        if (file.size > maxFileSize) {
          this.$toasted.error('사진 크기는 1M이하만 가능합니다.')
          return false
        } else if (!isAcceptable) {
          this.$toasted.error('허용하지 않는 파일 형식입니다.')
          return false
        } else {
          this.$toasted.show('사진이 업로드되었습니다.')
          this.isPhoto = true
        }
        if (isAcceptable) {
          // todo:: 파일업로드 api 태우고, 파일값 리턴받음.

          this.photoFileName = file.name
          console.log(file)

          try {
            const res = await uploadUserImg({ uploadFile: file })
            this.fileId = res
            var reader = new FileReader()
            reader.onload = e => {
              this.inputPhoto = e.target.result
            }
            reader.readAsDataURL(file)
            this.isDelete = false
          } catch (e) {
            console.error(e)
            // this.deleteAttachedFile()
            return
          }
        }
        // else {
        //   this.deleteAttachedFile()
        // }
      }
    },
    toggleAnswer(bordNo) {
      if (bordNo === 'close') {
        this.bordNum = null
      } else {
        this.bordNum = bordNo
      }
      this.$emit('toggleAnswer', this.bordNum)
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
  },
  async created() {
    await this.fetchPersonalInfo()
    this.serviceTypeCodeList = await getServiceTypeCodeList()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    console.log(this.serviceTypeCodeList)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
}
</script>

<style></style>
