<template>
  <div class="one-conten-con">
    <!-- <h2 class="h2-faq">자주 묻는 질문 TOP 20</h2> -->
    <div :style="InquiryStyle" class="search-box faq-search-box">
      <select
        v-if="account.levelNm === '관리자'"
        id="AnswerFillter"
        class="select"
        @change="AnswerFillter"
      >
        <option selected value="Full">전체</option>
        <option value="Comp">답변완료</option>
        <option value="Atmo">답변대기</option>
      </select>
      <input
        v-if="account.levelNm === '관리자'"
        v-model="searchText"
        type="text"
        class="text AnswerSearch"
        placeholder="검색어를 입력하세요."
      />
      <button
        v-if="account.levelNm === '관리자'"
        class="btn __search"
        @click="search"
      >
        검색
      </button>
      <button class="btn __search Inquiry" @click="apply">문의하기</button>
    </div>
    <ul class="one-list" id="oneList">
      <li v-for="(ones, idx) in orderedUsers" :key="`faq_${idx}`">
        <div
          class="q"
          :class="{ on: activeNum === ones.bordNo }"
          @click="openAnswer(ones.bordNo, idx)"
        >
          <div class="onesBordNm">
            {{ `${no - idx + `&#46;&nbsp;` + ones.bordNm}` }}
          </div>
          <div class="q3">
            <div class="onesDt">
              {{ formatDate(ones.regDt) }}
            </div>
            <span
              class="status"
              :class="
                ones.notcWght === 'H'
                  ? 'Comp'
                  : ones.notcWght === 'L'
                  ? 'Atmo'
                  : ''
              "
              >{{
                ones.notcWght === 'H'
                  ? '답변완료'
                  : ones.notcWght === 'L'
                  ? '답변대기'
                  : ' U'
              }}</span
            >
          </div>
        </div>
        <div class="a" :class="{ on: activeNum === ones.bordNo }">
          <div class="Inquiry-con">
            <h2 class="Inquiry-title">
              문의내용<span
                v-if="account.levelNm === '관리자'"
                class="respondent"
                >작성자 &nbsp;&#58;&nbsp;{{
                  ones.userNm + '[' + ones.regUser + ']'
                }}</span
              >
            </h2>

            <div class="Inquiry-content" v-html="ones.bordCont"></div>
            <h3 class="attachedFile-title">첨부파일</h3>
            <div v-if="ones.fileId === 0" class="attachedFile-content">
              첨부파일이 없습니다.
            </div>
            <div v-if="ones.fileId !== 0" class="attachedFile-content">
              <img :src="attachedFileImg(ones.fileId)" alt="" />
            </div>
          </div>
          <div class="Answer-con">
            <h2 class="Answer-title">
              답변<span v-if="account.levelNm === '관리자'" class="respondent"
                >답변자 &nbsp;&#58;&nbsp;{{ ones.modUser }}</span
              >
            </h2>
            <div v-if="account.levelNm === '관리자'" class="Answer-content">
              <textarea
                v-model="ones.bordRecont"
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <div v-else class="Answer-content">
              {{
                ones.notcWght === 'L'
                  ? '아직 답변이 등록되지 않았습니다.'
                  : ones.bordRecont
              }}
            </div>
          </div>
          <div
            v-if="account.levelNm === '관리자'"
            style="padding: 0"
            class="myp-btn-wrap"
          >
            <button type="button" class="btn-save01" @click="Answer(idx)">
              답변 등록
            </button>
          </div>
        </div>
      </li>
      <li v-if="oneOnOne.length == 0">
        <div class="q">
          <p
            class="q2"
            style="margin: auto; text-align: center; color: #090909"
          >
            문의하신 내용이 없습니다.
          </p>
        </div>
      </li>
    </ul>
    <one-apply
      v-if="isOneOnOne"
      :isOneOnOne="isOneOnOne"
      :propCd="propCd"
      @applyCancel="applyCancel"
      :class="{ on: isOneOnOne }"
    ></one-apply>
    <Pagination
      :totalElements="totalElements"
      :currentPageIndex="currentPageIndex"
      :listRowCount="listRowCount"
      :pageLinkCount="pageLinkCount"
      @goPage="onGoPage"
    >
    </Pagination>
  </div>
</template>

<script>
import { getBoardsList, putOneOnOneAnswer } from 'Api/modules'
import { URL } from 'Api/api'
import _ from 'lodash'
import Pagination from 'Common/Pagination'
import OneApply from 'Components/community/partials/OneApply'
import moment from 'moment'
export default {
  components: {
    Pagination,
    OneApply,
  },
  data() {
    return {
      activeNum: null,
      searchText: '',
      schFillter: '',
      oneOnOne: [],
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 20,
      pageLinkCount: 5,
      currentPageIndex: 1,
      propCd: '4',
      isOneOnOne: false,
      ix: null,
      modUser: '',
      window: {
        width: 0,
        height: 0,
      },
      //   statusText: '',
    }
  },
  watch: {
    window: {
      deep: true,
      handler() {
        if (this.window.width < 1023) {
          this.isNoticeWidth = false
        } else {
          this.isNoticeWidth = true
        }
        console.info(this.window.width)
      },
    },
  },
  computed: {
    InquiryStyle: function () {
      if (this.account.levelNm === '관리자' && this.window.width < 1024) {
        return 'justify-content:space-between;'
      } else if (
        this.account.levelNm === '관리자' &&
        this.window.width > 1024
      ) {
        return 'justify-content: flex-end;'
      } else {
        return 'justify-content:end;'
      }
    },
    orderedUsers: function () {
      //내림차순 : H < - > L 순서 변경
      return _.orderBy(this.oneOnOne, 'notcWght', 'desc')
    },
    CompList: function () {
      return _.filter(this.oneOnOne, oneOnOne => oneOnOne.notcWght === 'H')
    },
    AtmoList: function () {
      return _.filter(this.oneOnOne, oneOnOne => oneOnOne.notcWght === 'L')
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    async AnswerFillter() {
      var AnswerFillter = document.getElementById('AnswerFillter')
      var AnswerText = AnswerFillter.options[AnswerFillter.selectedIndex].value
      console.info(AnswerFillter)
      console.info(AnswerText)
      if (AnswerText === 'Full') {
        await this.search()
      } else if (AnswerText === 'Comp') {
        await this.search()
        this.oneOnOne = await this.CompList
      } else if (AnswerText === 'Atmo') {
        await this.search()
        this.oneOnOne = await this.AtmoList
      }
    },
    async Answer(idx) {
      let saveData
      saveData = {
        bordRecont: this.oneOnOne[idx].bordRecont,
        modUser: this.account.userNm,
        bordNo: String(this.oneOnOne[idx].bordNo),
      }
      try {
        await putOneOnOneAnswer(saveData)
        setTimeout(() => {
          this.$router.go()
        }, 500)

        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
    apply() {
      this.isOneOnOne = true
      window.$('.container,.__contact,.sub-cont').css('position', 'static')
      window.$('.mobileTopMenu').css('z-index', '0')
      window.$('.container').css('overflow-y', 'inherit')
    },
    applyCancel() {
      this.isOneOnOne = false
      window.$('.container,.__contact,.sub-cont').css('position', 'relative')
      window.$('.mobileTopMenu').css('z-index', '1')
      window.$('.container').css('overflow-y', 'auto')
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    },
    async search() {
      const res = await getBoardsList({
        schKeyword: this.searchText,
        pageNumber: this.pageNumber,
        propCd: this.propCd,
        pageSize: this.pageSize,
      })
      this.oneOnOne = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      this.pageSize = res.pageable.pageSize
      this.no = this.totalElements - (this.currentPageIndex - 1) * this.pageSize
      var count = window.$('#AnswerFillter > option:selected').val()
      if (count == 'Full') {
        this.oneOnOne = res.content
      } else if (count == 'Comp') {
        this.oneOnOne = this.CompList
      } else if (count == 'Atmo') {
        this.oneOnOne = this.AtmoList
      }
    },
    openAnswer(faqId) {
      if (this.activeNum === faqId) this.activeNum = null
      else {
        this.activeNum = faqId
        // getFaqDetail
      }
    },
    attachedFileImg(fileId) {
      return `${URL}/api/hgc/file/view/${fileId}`
    },
  },
  created() {
    if (this.account.levelNm === '관리자') this.searchText = ''
    else this.searchText = this.account.userId
    console.info('bordRecont', this.bordRecont)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.search()
  },
}
</script>

<style></style>
